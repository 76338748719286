import React, { useState } from "react";
import { Button, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import {
  ABI_STAKE,
  CONTRACT_STAKE,
  BNB_TOKEN_ADDRESS, ABI_TRADE, CONTRACT_TRADE
} from "../../web3/utils";

const BuyTokensModal = ({ setHash }) => {
  const { account, library } = useWeb3React();
  const [modal, setModal] = useState(false);
  const [amount, setAmount] = useState(undefined);
  const [toggleSlippage, setToggleSlippage] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const Buy = async (e) => {
    e.preventDefault();
    try {
      if (!library) return;
      const web3 = new Web3(library);
      const contractTrade = new web3.eth.Contract(ABI_TRADE, CONTRACT_TRADE);
      const contract = new web3.eth.Contract(ABI_STAKE, CONTRACT_STAKE);

      setLoading(true);

      const isLimit = await contractTrade.methods.isLimit().call();

      if (isLimit) {
        await contractTrade.methods
          .buyTokens()
          .send({value: (Number(amount) * Math.pow(10, 18)).toString(), from: account})
          .then((res) => {
            setHash(res.transactionHash);
            setLoading(false);
            toggle();
          })
          .catch((err) => {
            console.log("-buy tokens method error:", err);
          });
      } else {
        let amountMin;
        if (toggleSlippage) {
          amountMin = await contractTrade.methods.getAmountOutMin(BNB_TOKEN_ADDRESS, (Number(amount) * Math.pow(10, 18)).toString()).call();
        } else {
          amountMin = await contract.methods.getAmountOutMin(BNB_TOKEN_ADDRESS, (Number(amount) * Math.pow(10, 18)).toString()).call();
        }
        await contract.methods
          .buyTokens(account, amountMin.toString())
          .send({value: (Number(amount) * Math.pow(10, 18)).toString(), from: account})
          .then((res) => {
            setHash(res.transactionHash);
            setLoading(false);
            toggle();
          })
          .catch((err) => {
            console.log("buy tokens method error:", err);
          });
      }
    } catch (e) {
      console.error("buy error:", e);
    }
  };

  return (
    <>
      <button className="btn btn-primary" title="Get AIST to start Staking up to 120% per annum" onClick={toggle}>
        Buy AIST
      </button>
      <Modal isOpen={modal} toggle={toggle} className={"modal-app"}>
        <ModalHeader toggle={toggle}>Buy AIST</ModalHeader>
        <ModalBody>
          <Form className="form">
            <div className="form-control">
              <Input
                type="text"
                id="amount"
                value={amount}
                onChange={(e) => setAmount(e.currentTarget.value)}
              />
              <Label htmlFor="amount">amount BNB</Label>
            </div>
            <div className="toggle-switch-container" onClick={() => setToggleSlippage(!toggleSlippage)}>
              <span className="text-label">{toggleSlippage ? "High slippage" : "Low slippage"}</span>
              <Input
                type="checkbox"
                id="exampleSwitch"
                checked={toggleSlippage}
                className="switch"
                readOnly
              />
              <span className="slider"></span>
            </div>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type={"submit"} onClick={(e) => Buy(e)}>
            Send
          </Button>
          <Button color="primary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
        <div className={"loading-holder " + (loading ? "active" : "")}>
          <div className={"lds-ellipsis"}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default BuyTokensModal;
