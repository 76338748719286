import {
  BNB_TOKEN_ADDRESS,
  USDT_TOKEN_ADDRESS,
  CONTRACT_ROUTER,
  ABI_ROUTER,
  BLOCKCHAIN_RPC_URL,
  ABI_STAKE,
  CONTRACT_STAKE
} from "./utils";

const Web3 = require('web3');

export const convert = (value, decimals) => {
  value = Number(value) / Math.pow(10, decimals);
  return (value);
}

export const numberToStr = (value, decimals) => {
  return value.toFixed(decimals);
}

async function calcSell(){
  const web3 = new Web3(BLOCKCHAIN_RPC_URL);

  let amountOut;
  try {
    let stakeContract = await new web3.eth.Contract(ABI_STAKE, CONTRACT_STAKE);
    amountOut = await stakeContract.methods.getPrice().call();
    console.log("---amountOut:", amountOut);
    amountOut = web3.utils.fromWei(amountOut);
  } catch (error) {
    console.log("calc sell - get amount out min out method error:", error);
  }

  if (!amountOut) return 0;
  return amountOut;
}

async function calcBNBPrice(){
  const web3 = new Web3(BLOCKCHAIN_RPC_URL);
  let bnbToSell = web3.utils.toWei("1", "ether") ;
  let amountOut;
  try {
    let router = await new web3.eth.Contract(ABI_ROUTER, CONTRACT_ROUTER);
    amountOut = await router.methods.getAmountsOut(bnbToSell, [BNB_TOKEN_ADDRESS, USDT_TOKEN_ADDRESS]).call();
    console.log("---amountOut BNB:", amountOut);
    amountOut =  web3.utils.fromWei(amountOut[1]);
  } catch (error) {
    console.log("calc bnb price - get amounts out method error:", error);
  }
  if (!amountOut) return 0;
  return amountOut;
}

export async function getPrice() {
  let bnbPrice = await calcBNBPrice() // query pancakeswap to get the price of BNB in USDT
  console.log("bnbPrice:", bnbPrice)
  let priceInBnb = await calcSell(); // calculate TOKEN price in BNB
  console.log("priceInBnb:", priceInBnb)
  let priceInUsdt = bnbPrice * priceInBnb;
  return { priceInBnb, priceInUsdt };
}

export async function getFee(){
  const web3 = new Web3(BLOCKCHAIN_RPC_URL);

  const stakingContract = await new web3.eth.Contract(ABI_STAKE, CONTRACT_STAKE);
  let fee = 0

  try {
    fee = await stakingContract.methods.fee().call();
  } catch (error) {
    console.log("fee method error:", error);
  }

  return fee;
}