import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import { ABI_STAKE, ABI_TOKEN, CONTRACT_STAKE, CONTRACT_TOKEN, STAKE_DAYS_PERIODS, ZERO_ADDRESS } from "../../web3/utils";

const StakeModal = ({setHash, fee}) => {
  const {account, active, library} = useWeb3React();
  const [modal, setModal] = useState(false);
  const [amount, setAmount] = useState(100);
  const [referrer, setReferrer] = useState("");
  const [dayPeriod, setDayPeriod] = useState(STAKE_DAYS_PERIODS[0]);
  const [status, setStatus] = useState(0);
  const [loading, setLoading] = useState(false);
  const [allowance, setAllowance] = useState(0);
  const [balance, setBalance] = useState(0);

  const toggle = () => {
    setModal(!modal);
  };

  const allow = async () => {
    try {
      if (!library) return;
      const web3 = new Web3(library);
      const contract = new web3.eth.Contract(ABI_TOKEN, CONTRACT_TOKEN);

      await contract.methods
        .balanceOf(account)
        .call()
        .then((res) => {
          setBalance(res)
        });

      await contract.methods
        .allowance(account, CONTRACT_STAKE)
        .call()
        .then((res) => {
          setAllowance(res);
        });
    } catch (e) {
      console.error("allow error:", e);
    }
  }

  const Approve = async (e) => {
    e.preventDefault();
    try {
      if (!library) return;
      const web3 = new Web3(library);
      const contract = new web3.eth.Contract(ABI_TOKEN, CONTRACT_TOKEN);
      setLoading(true)
      await contract.methods
        .approve(CONTRACT_STAKE, balance)
        .send({from: account})
        .then((res) => {
          setStatus(1);
          setHash(res.transactionHash);
          setLoading(false);
        })
        .catch(err=> {
          console.log("approve method error:", err);
        })
      } catch (e) {
        console.error("approve error:", e);
      }
    }

  const Stake = async (e) => {
    e.preventDefault();
    try {
      if(!library) return;
      const web3 = new Web3(library);
      const contract = new web3.eth.Contract(ABI_STAKE, CONTRACT_STAKE);
      setLoading(true);
      const _referrer = referrer === "" ? ZERO_ADDRESS : referrer;
      await contract.methods.stake(
        web3.utils.toBN(amount).mul(web3.utils.toBN(10).pow(web3.utils.toBN(18))).toString(),
        dayPeriod.toString(),
        _referrer
      ).send({value: fee,from: account}).then(res=> {
        setHash(res.transactionHash);
        setLoading(false);
        toggle();
      }).catch(err=> {
        console.log("stake method error:", err);
      })
    } catch (e) {
      console.error("stake error:", e);
    }
  }

  useEffect(()=> {
    allow();
  }, [library, modal, account, active])

  useEffect(()=> {
    if (Number(balance) === 0) {
      setStatus(0);
    } else if (Number(allowance) < Number(balance)) {
      setStatus(1);
    } else {
      setStatus(2);
    }
  }, [allowance, balance]);

  return (
    <>
      <button className="btn btn-primary" title="You have to use this button twice. 1st time - to approve our dapp in your wallet and grant a permission. 2nd - to activate a staking." onClick={toggle}>
        New Stake
      </button>
      <Modal isOpen={modal} toggle={toggle} className={'modal-app'}>
        <ModalHeader toggle={toggle}>
          New stake
        </ModalHeader>
        <ModalBody>
          <Form className="form">
            <div className="form-control">
              <Input type="text" id="amount" value={amount} onChange={e => setAmount(e.currentTarget.value)}/>
              <Label htmlFor="amount">amount</Label>
            </div>
            <div className="form-control">
              <select id="dayPeriod" value={dayPeriod} onChange={e => setDayPeriod(e.currentTarget.value)}>
                {STAKE_DAYS_PERIODS.map(period => (
                  <option key={period} value={period}>{period}</option>
                ))}
              </select>
              <Label htmlFor="dayPeriod">days</Label>
            </div>
            <div className="form-control">
              <Input type="text" id="referrer" value={referrer} onChange={e => setReferrer(e.currentTarget.value)}/>
              <Label htmlFor="referrer">referrer</Label>
            </div>
          </Form>
        </ModalBody>
        <ModalFooter>
          {status === 2 ? (
            <Button color="primary" type={'submit'} onClick={e => Stake(e)}>
              Send
            </Button>
          ) : status === 1 ? (
            <Button color="primary" type={'submit'} onClick={e=>Approve(e)}>
              Approve
            </Button>
          ) : null}
          <Button color="primary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
        <div className={"loading-holder "+ (loading ? 'active' : '')}>
          <div className={"lds-ellipsis"}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default StakeModal;