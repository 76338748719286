import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useWeb3React } from "@web3-react/core";
import { connectors } from "../../web3/connectors";

const ConnectWallet = () => {
  const { activate } = useWeb3React()
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const Metamask = async (e) => {
    e.preventDefault();
    try {
      await activate(connectors.inject).then(()=>{
        toggle();
      });
    } catch (e) {
      console.log("metamask error:", e)
    }
  }

  const WalletConnects = async () => {
    try {
      await activate(connectors.walletConnect).then(()=>{
        toggle();
      });
    } catch (e) {
      console.log("walletconnect error:", e);
    }
  }
  return (
    <>
      <button className="btn btn-primary btn-connect" onClick={toggle}>Connect Wallet</button>
      <Modal isOpen={modal} toggle={toggle} className={'modal-app modal-connect'}>
        <ModalHeader toggle={toggle}>
          Connect Wallet
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-6 connect-item">
              <a onClick={(e) => Metamask(e)} className="connect-link">
                <span className="ico">
                  <svg width="257" height="238" viewBox="0 0 257 238" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M243.1 0.5L143.6 74.4L162 30.8L243.1 0.5Z" fill="#E2761B" stroke="#E2761B"
                          strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M13.3999 0.5L112.1 75.1L94.5999 30.8L13.3999 0.5Z" fill="#E4761B" stroke="#E4761B"
                          strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M207.3 171.8L180.8 212.4L237.5 228L253.8 172.7L207.3 171.8Z" fill="#E4761B"
                          stroke="#E4761B" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M2.8999 172.7L19.0999 228L75.7999 212.4L49.2999 171.8L2.8999 172.7Z" fill="#E4761B"
                          stroke="#E4761B" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M72.5998 103.2L56.7998 127.1L113.1 129.6L111.1 69.1001L72.5998 103.2Z" fill="#E4761B"
                          stroke="#E4761B" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M183.9 103.2L144.9 68.3999L143.6 129.6L199.8 127.1L183.9 103.2Z" fill="#E4761B"
                          stroke="#E4761B" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M75.7998 212.4L109.6 195.9L80.3998 173.1L75.7998 212.4Z" fill="#E4761B" stroke="#E4761B"
                          strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M146.9 195.9L180.8 212.4L176.1 173.1L146.9 195.9Z" fill="#E4761B" stroke="#E4761B"
                          strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M180.8 212.4L146.9 195.9L149.6 218L149.3 227.3L180.8 212.4Z" fill="#D7C1B3"
                          stroke="#D7C1B3" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M75.7998 212.4L107.3 227.3L107.1 218L109.6 195.9L75.7998 212.4Z" fill="#D7C1B3"
                          stroke="#D7C1B3" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M107.8 158.5L79.6001 150.2L99.5001 141.1L107.8 158.5Z" fill="#233447" stroke="#233447"
                          strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M148.7 158.5L157 141.1L177 150.2L148.7 158.5Z" fill="#233447" stroke="#233447"
                          strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M75.7998 212.4L80.5998 171.8L49.2998 172.7L75.7998 212.4Z" fill="#CD6116" stroke="#CD6116"
                          strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M176 171.8L180.8 212.4L207.3 172.7L176 171.8Z" fill="#CD6116" stroke="#CD6116"
                          strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M199.8 127.1L143.6 129.6L148.8 158.5L157.1 141.1L177.1 150.2L199.8 127.1Z" fill="#CD6116"
                          stroke="#CD6116" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M79.5998 150.2L99.5998 141.1L107.8 158.5L113.1 129.6L56.7998 127.1L79.5998 150.2Z"
                          fill="#CD6116" stroke="#CD6116" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M56.7998 127.1L80.3998 173.1L79.5998 150.2L56.7998 127.1Z" fill="#E4751F" stroke="#E4751F"
                          strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M177.1 150.2L176.1 173.1L199.8 127.1L177.1 150.2Z" fill="#E4751F" stroke="#E4751F"
                          strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M113.1 129.6L107.8 158.5L114.4 192.6L115.9 147.7L113.1 129.6Z" fill="#E4751F"
                          stroke="#E4751F" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M143.6 129.6L140.9 147.6L142.1 192.6L148.8 158.5L143.6 129.6Z" fill="#E4751F"
                          stroke="#E4751F" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M148.8 158.5L142.1 192.6L146.9 195.9L176.1 173.1L177.1 150.2L148.8 158.5Z" fill="#F6851B"
                          stroke="#F6851B" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M79.6001 150.2L80.4001 173.1L109.6 195.9L114.4 192.6L107.8 158.5L79.6001 150.2Z"
                          fill="#F6851B" stroke="#F6851B" strokeLinecap="round" strokeLinejoin="round"/>
                    <path
                      d="M149.3 227.3L149.6 218L147.1 215.8H109.4L107.1 218L107.3 227.3L75.7998 212.4L86.7998 221.4L109.1 236.9H147.4L169.8 221.4L180.8 212.4L149.3 227.3Z"
                      fill="#C0AD9E" stroke="#C0AD9E" strokeLinecap="round" strokeLinejoin="round"/>
                    <path
                      d="M146.9 195.9L142.1 192.6H114.4L109.6 195.9L107.1 218L109.4 215.8H147.1L149.6 218L146.9 195.9Z"
                      fill="#161616" stroke="#161616" strokeLinecap="round" strokeLinejoin="round"/>
                    <path
                      d="M247.3 79.2L255.8 38.4L243.1 0.5L146.9 71.9L183.9 103.2L236.2 118.5L247.8 105L242.8 101.4L250.8 94.1L244.6 89.3L252.6 83.2L247.3 79.2Z"
                      fill="#763D16" stroke="#763D16" strokeLinecap="round" strokeLinejoin="round"/>
                    <path
                      d="M0.799805 38.4L9.2998 79.2L3.89981 83.2L11.8998 89.3L5.7998 94.1L13.7998 101.4L8.7998 105L20.2998 118.5L72.5998 103.2L109.6 71.9L13.3998 0.5L0.799805 38.4Z"
                      fill="#763D16" stroke="#763D16" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M236.2 118.5L183.9 103.2L199.8 127.1L176.1 173.1L207.3 172.7H253.8L236.2 118.5Z"
                          fill="#F6851B" stroke="#F6851B" strokeLinecap="round" strokeLinejoin="round"/>
                    <path
                      d="M72.5999 103.2L20.2999 118.5L2.8999 172.7H49.2999L80.3999 173.1L56.7999 127.1L72.5999 103.2Z"
                      fill="#F6851B" stroke="#F6851B" strokeLinecap="round" strokeLinejoin="round"/>
                    <path
                      d="M143.6 129.6L146.9 71.8998L162.1 30.7998H94.6001L109.6 71.8998L113.1 129.6L114.3 147.8L114.4 192.6H142.1L142.3 147.8L143.6 129.6Z"
                      fill="#F6851B" stroke="#F6851B" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
                <strong>MetaMask</strong>
                <span className="text">Connect to your MetaMask Wallet</span>
              </a>
            </div>
            <div className="col-md-6 connect-item">
              <a onClick={(e) => WalletConnects(e)} className="connect-link">
                <span className="ico">
                  <svg width="512" height="512" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd"
                        d="M256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0Z"
                        fill="url(#paint0_radial_401_219)"/>
                  <path
                    d="M162.7 197.7C214.2 147.4 297.8 147.4 349.3 197.7L355.5 203.8C358.1 206.3 358.1 210.4 355.5 212.9L334.3 233.6C333 234.9 330.9 234.9 329.6 233.6L321.1 225.3C285.1 190.2 226.9 190.2 190.9 225.3L181.8 234.2C180.5 235.5 178.4 235.5 177.1 234.2L155.9 213.5C153.3 211 153.3 206.9 155.9 204.4L162.7 197.7ZM393.2 240.5L412.1 258.9C414.7 261.4 414.7 265.5 412.1 268L327 351.1C324.4 353.6 320.2 353.6 317.7 351.1L257.3 292.1C256.7 291.5 255.6 291.5 255 292.1L194.6 351.1C192 353.6 187.8 353.6 185.3 351.1L99.9002 268C97.3002 265.5 97.3002 261.4 99.9002 258.9L118.8 240.5C121.4 238 125.6 238 128.1 240.5L188.5 299.5C189.1 300.1 190.2 300.1 190.8 299.5L251.2 240.5C253.8 238 258 238 260.5 240.5L320.9 299.5C321.5 300.1 322.6 300.1 323.2 299.5L383.6 240.5C386.4 238 390.6 238 393.2 240.5Z"
                    fill="white"/>
                  <defs>
                  <radialGradient id="paint0_radial_401_219" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                  gradientTransform="translate(0.00158691 256.008) scale(512)">
                  <stop stopColor="#5D9DF6"/>
                  <stop offset="1" stopColor="#006FFF"/>
                  </radialGradient>
                  </defs>
                  </svg>
                </span>
                <strong>WalletConnect</strong>
                <span className="text">Scan with WalletConnect to connect</span>
              </a>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

export default ConnectWallet;
