import React, { useEffect, useState } from "react";

import {
  BuyCoinsModal,
  BuyTokensModal,
  StakeModal,
  CloseStakeModal,
  ReInvestModal,
  WithdrawModal,
  ClaimModal,
  BoostsModal
} from "../../components/modal";
import ProgressBar from '../../components/extra/ProgressBar';
import { getPrice, convert, numberToStr, getFee } from "../../web3/helpers";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import {
  ABI_STAKE,
  ABI_TOKEN,
  CONTRACT_STAKE,
  CONTRACT_TOKEN
} from "../../web3/utils";

const Dashboard = () => {
  const {active, account, library} = useWeb3React()
  const [hash, setHash] = useState();
  const [balance, setBalance] = useState(0);
  const [priceUSDT, setPriceUSDT] = useState(0);
  const [priceCOIN, setPriceCOIN] = useState(0);
  const [fee, setFee] = useState(0);
  const [userData, setUserData] = useState({
    stakeAmount: 0,
    stakeDayPeriod: 0,
    stakePercent: 0,
    claimHourPeriod: 0,
    claimPercent: 0,
    reward: 0,
    claimAmount: 0,
    claimLeftMinutes: 0,
    claimTimePercent: 0,
    stakeDays: 0,
  });

  const toUsdt = ( value ) => {
    return (value * priceUSDT);
  }

  const toCoin = ( value ) => {
    return (value * priceCOIN);
  }

  const loadInfo = async () => {
    try {
      if (!library) return;
      const web3 = new Web3(library);
      const contract = new web3.eth.Contract(ABI_STAKE, CONTRACT_STAKE);
      const token = new web3.eth.Contract(ABI_TOKEN, CONTRACT_TOKEN);
      await token.methods.balanceOf(account).call()
        .then(res => {
          setBalance(res);
        })
        .catch(err=> {console.error("balance method error:", err)});

      await contract.methods.getUserData(account).call()
        .then(res=> {
          setUserData({
            stakeAmount: Number(res[0]),
            stakeDayPeriod: Number(res[1]),
            stakePercent: Number(res[2]),
            claimHourPeriod: Number(res[3]),
            claimPercent: Number(res[4]),
            reward: Number(res[5]),
            claimAmount: Number(res[6]),
            claimLeftMinutes: Number(res[8]),
            claimTimePercent: Number(res[9]),
            stakeDays: Number(res[10]),
          });
        })
        .catch(err=> {console.error("stakes method error:", err)});

      getPrice().then(res=> {
        setPriceCOIN(res.priceInBnb);
        setPriceUSDT(res.priceInUsdt);
      }).catch(err=> {console.error("get price error:", err)});
    } catch (e) {
      console.error("load info error:", e);
    }
  }

  useEffect(()=> {
    loadInfo();
  }, [library, account, active, hash]);

  useEffect(() => {
    const id = setInterval(async ()=> {
      await loadInfo();
    }, 1000 * 10);
    return ()=> clearInterval(id);
  }, [userData, account, hash])

  useEffect(()=> {
    async function fetchFee() {
      setFee(await getFee());
    }
    fetchFee();
  }, []);

  return (
    <>
      <div className="dashboard-block">
        <div className="holder-block">
          <div className="block-wrapper balance-block">
            <div className="holder">
              <h3 className="title">
                <span className="border-text" title="This is your active AIST balance. You can send it to Stake or Swap at Pancake swap">Balance</span>
              </h3>
              <div className="balance-info">
                <div className="left">
                  <strong className="number">
                    {numberToStr(convert(balance, 18), 8)} <img width="35px" src="favicon.svg" alt="AIST"/>
                  </strong>
                </div>
                <div className="left">
                  <div className="current-info" title="The value of your AIST tokens in this wallet section in BNB">
                    <span className="name">BNB</span>
                    <strong>{numberToStr(toCoin(convert(balance, 18)), 4)}</strong>
                  </div>
                  <div className="current-info" title="The value of your AIST tokens in this wallet section in USDT">
                    <span className="name">USDT</span>
                    <strong>{numberToStr(toUsdt(convert(balance, 18)), 4)}</strong>
                  </div>
                </div>
                <div className="left">
                  <div className="extra-info">
                    <div className="btn-holder">
                      <BuyCoinsModal setHash={setHash}/>
                      <BuyTokensModal setHash={setHash}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="block-wrapper reward-block">
            <div className="holder">
              <h3 className="title">
                <span className="border-text" title="All AIST you Claim shows here. You can reinvest it to staking or send to AIST balance">Reward</span>
              </h3>
              <div className="balance-info">
                <div className="left">
                  <strong className="number">
                    {numberToStr(convert(userData.reward, 18), 8)} <img width="35px" src="favicon.svg" alt="AIST"/>
                  </strong>
                </div>
                <div className="left">
                  <div className="current-info" title="The value of your AIST tokens in this wallet section in BNB">
                    <span className="name">BNB</span>
                    <strong>{numberToStr(toCoin(convert(userData.reward, 18)), 4)}</strong>
                  </div>
                  <div className="current-info" title="The value of your AIST tokens in this wallet section in USDT">
                    <span className="name">USDT</span>
                    <strong>{numberToStr(toUsdt(convert(userData.reward, 18)), 4)}</strong>
                  </div>
                </div>
                <div className="left">
                  <div className="extra-info">
                    <div className="btn-holder">
                      <ReInvestModal setHash={setHash} fee={fee}/>
                      <WithdrawModal setHash={setHash} fee={fee}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="block-wrapper stake-block">
            <div className="holder">
              <h3 className="title">
                <span className="border-text" title="You can pick one of three staking period - 90, 180 or 360 days lock out. During this period you cannot withdraw this tokens without penalty (40% of staking amount). For 90 days program you will have 0,15% daily as a staking speed, for 180 - 0,24% and for 360 days - 0,3%">Staking</span>
              </h3>
              <div className="balance-info">
                <div className="left">
                  <strong className="number">
                    {numberToStr(convert(userData.stakeAmount, 18), 8)} <img width="35px" src="favicon.svg" alt="AIST"/>
                  </strong>
                </div>
                <div className="left">
                  <div className="current-info" title="The value of your AIST tokens in this wallet section in BNB">
                    <span className="name">BNB</span>
                    <strong>{numberToStr(toCoin(convert(userData.stakeAmount, 18)), 4)}</strong>
                  </div>
                  <div className="current-info" title="The value of your AIST tokens in this wallet section in USDT">
                    <span className="name">USDT</span>
                    <strong>{numberToStr(toUsdt(convert(userData.stakeAmount, 18)), 4)}</strong>
                  </div>
                  <br/>
                  <div className="current-info" title="Base your steaking rate on the selected token freeze period. You can multiply this parameter by 365 days to calculate your income in tokens in annualized terms.">
                    <span className="name">Daily income</span>
                    <strong>{numberToStr(convert(userData.stakePercent, 4), 2)}%</strong>
                  </div>
                  <div className="current-info" title="Time Period of your staking">
                    <span className="name">Overall days</span>
                    <strong>{numberToStr(convert(userData.stakeDays, 2), 2)}/{userData.stakeDayPeriod}</strong>
                  </div>
                </div>
                <div className="left">
                  <div className="extra-info">
                    <div className="btn-holder">
                      <StakeModal setHash={setHash} fee={fee}/>
                      <CloseStakeModal setHash={setHash} fee={fee}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="block-wrapper claim-block">
            <div className="holder">
              <h3 className="title">
                <span className="border-text" title="This is an active part of staking program. After staking contract activated you will start to receive new AIST from global staking wallet, base on your lock out speed and additional boosters for staking speed. Base storage capacity is 3 hours. After this period staking will be de-activated till you claim your tokens and free storage for new tokens. You can also boost your Storage capacity in Boost section">Storage</span>
              </h3>
              <div className="balance-info">
                <div className="left">
                  <strong className="number">
                    {numberToStr(convert(convert(userData.claimAmount, 2), 18), 8)} <img width="35px" src="favicon.svg" alt="AIST"/>
                  </strong>
                  <ProgressBar percent={convert(userData.claimTimePercent, 4)} />
                </div>

                <div className="left">
                  <div className="current-info" title="The value of your AIST tokens in this wallet section in BNB">
                    <span className="name">BNB</span>
                    <strong>{numberToStr(toCoin(convert(convert(userData.claimAmount, 2), 18)), 4)}</strong>
                  </div>
                  <div className="current-info" title="The value of your AIST tokens in this wallet section in USDT">
                    <span className="name">USDT</span>
                    <strong>{numberToStr(toUsdt(convert(convert(userData.claimAmount, 2), 18)), 4)}</strong>
                  </div>
                  <br/>
                  <strong className="current-info" title="Addtional speed booster base on your storage-speed level. This booster multiplies the base speed of your staking program.">
                    <span className="name">Speed</span>
                    <strong>{numberToStr(convert(userData.claimPercent, 4), 2)}%</strong>
                  </strong>
                  <div className="current-info" title="Minutes mount until storage is full">
                    <span className="name">Time left</span>
                    <strong>{numberToStr(convert(userData.claimLeftMinutes, 2), 2)} min</strong>
                  </div>
                </div>

                <div className="left">
                <div className="extra-info">
                  <div className="btn-holder">
                    <ClaimModal setHash={setHash} fee={fee}/>
                    <BoostsModal setHash={setHash} fee={fee} amount={userData.stakeAmount} claimPercent={userData.claimPercent} claimHourPeriod={userData.claimHourPeriod}/>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Dashboard;