import React, { useEffect, useState } from "react";
import { Button, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import {
  ABI_STAKE,
  ABI_TOKEN,
  ABI_TRADE,
  CONTRACT_STAKE,
  CONTRACT_TOKEN,
  CONTRACT_TRADE
} from "../../web3/utils";

const BuyCoinsModal = ({ setHash }) => {
  const { account, active, library } = useWeb3React();
  const [modal, setModal] = useState(false);
  const [amount, setAmount] = useState(undefined);
  const [toggleSlippage, setToggleSlippage] = useState(false);
  const [status, setStatus] = useState(0);
  const [loading, setLoading] = useState(false);
  const [allowance, setAllowance] = useState(0);
  const [balance, setBalance] = useState(0);

  const toggle = () => {
    setModal(!modal);
  };

  const allow = async () => {
    try {
      if (!library) return;
      const web3 = new Web3(library);
      const contract = new web3.eth.Contract(ABI_TOKEN, CONTRACT_TOKEN);

      await contract.methods
        .balanceOf(account)
        .call()
        .then((res) => {
          setBalance(res);
        });

      await contract.methods
        .allowance(account, CONTRACT_STAKE)
        .call()
        .then((res) => {
          setAllowance(res);
        });
    } catch (e) {
      console.error("allow error:", e);
    }
  };

  const Approve = async (e) => {
    e.preventDefault();
    try {
      if (!library) return;
      const web3 = new Web3(library);
      const contract = new web3.eth.Contract(ABI_TOKEN, CONTRACT_TOKEN);
      setLoading(true);
      await contract.methods
        .approve(CONTRACT_STAKE, balance)
        .send({ from: account })
        .then((res) => {
          setStatus(2);
          setHash(res.transactionHash);
          setLoading(false);
        })
        .catch((err) => {
          console.log("approve method error:", err);
        });
    } catch (e) {
      console.error("approve error:", e);
    }
  };

  const Buy = async (e) => {
    e.preventDefault();
    try {
      if (!library) return;
      const web3 = new Web3(library);

      const contractTrade = new web3.eth.Contract(ABI_TRADE, CONTRACT_TRADE);
      const contract = new web3.eth.Contract(ABI_STAKE, CONTRACT_STAKE);
      setLoading(true);

      let amountMin;
      if (toggleSlippage) {
        amountMin = await contractTrade.methods.getAmountOutMin(CONTRACT_TOKEN, (Number(amount) * Math.pow(10, 18)).toString()).call();
      } else {
        amountMin = await contract.methods.getAmountOutMin(CONTRACT_TOKEN, (Number(amount) * Math.pow(10, 18)).toString()).call();
      }
      await contract.methods
        .buyCoins((Number(amount) * Math.pow(10, 18)).toString(), amountMin.toString())
        .send({ from: account })
        .then((res) => {
          setHash(res.transactionHash);
          setLoading(false);
          toggle();
        })
        .catch((err) => {
          console.log("buy coins method error:", err);;
        });
    } catch (e) {
      console.error("buy error:", e);
    }
  };

  useEffect(() => {
    allow();
  }, [library, modal, account, active]);

  useEffect(() => {
    if (Number(balance) === 0) {
      setStatus(0);
    } else if (Number(allowance) < Number(balance)) {
      setStatus(1);
    } else {
      setStatus(2);
    }
  }, [allowance, balance]);

  return (
    <>
      <button className="btn btn-primary btn-selected" title="Get some BNB to pay transaction GAS fee" onClick={toggle}>
        Sell AIST
      </button>
      <Modal isOpen={modal} toggle={toggle} className={"modal-app"}>
        <ModalHeader toggle={toggle}>Sell AIST</ModalHeader>
        <ModalBody>
          <Form className="form">
            <div className="form-control">
              <Input
                type="text"
                id="amount"
                value={amount}
                onChange={(e) => setAmount(e.currentTarget.value)}
              />
              <Label htmlFor="amount">amount AIST</Label>
            </div>
            <div className="toggle-switch-container" onClick={() => setToggleSlippage(!toggleSlippage)}>
              <span className="text-label">{toggleSlippage ? "High slippage" : "Low slippage"}</span>
              <Input
                type="checkbox"
                id="exampleSwitch"
                checked={toggleSlippage}
                className="switch"
                readOnly
              />
              <span className="slider"></span>
            </div>
          </Form>
        </ModalBody>
        <ModalFooter>
          {status === 2 ? (
            <Button color="primary" type={"submit"} onClick={(e) => Buy(e)}>
              Send
            </Button>
          ) : status === 1 ? (
            <Button color="primary" type={"submit"} onClick={(e) => Approve(e)}>
              Approve
            </Button>
          ) : null}
          <Button color="primary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
        <div className={"loading-holder " + (loading ? "active" : "")}>
          <div className={"lds-ellipsis"}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default BuyCoinsModal;
