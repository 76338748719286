import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardText, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import { convert, numberToStr } from "../../web3/helpers";
import {
  ABI_STAKE,
  ABI_TOKEN,
  CONTRACT_STAKE,
  CONTRACT_TOKEN
} from "../../web3/utils";

const BOOST_TYPES = ["Upgrade Claiming speed", "Increase time capacity"];

const BoostsModal = ({ setHash, fee, amount, claimPercent, claimHourPeriod }) => {
  const { account, active, library } = useWeb3React();
  const [modal, setModal] = useState(false);
  const [infoText, setInfoText] = useState([]);
  const [boostType, setBoostType] = useState(BOOST_TYPES[0]);
  const [status, setStatus] = useState(0);
  const [loading, setLoading] = useState(false);
  const [allowance, setAllowance] = useState(0);
  const [balance, setBalance] = useState(0);

  const toggle = () => {
    setModal(!modal);
  };

  const allow = async () => {
    try {
      if (!library) return;
      const web3 = new Web3(library);
      const contract = new web3.eth.Contract(ABI_TOKEN, CONTRACT_TOKEN);

      await contract.methods
        .balanceOf(account)
        .call()
        .then((res) => {
          setBalance(res);
        });

      await contract.methods
        .allowance(account, CONTRACT_STAKE)
        .call()
        .then((res) => {
          setAllowance(res);
        });
    } catch (e) {
      console.error("allow error:", e);
    }
  }

  const Approve = async (e) => {
    e.preventDefault();
    try {
      if (!library) return;
      const web3 = new Web3(library);
      const contract = new web3.eth.Contract(ABI_TOKEN, CONTRACT_TOKEN);
      setLoading(true);
      await contract.methods
        .approve(CONTRACT_STAKE, balance)
        .send({ from: account })
        .then((res) => {
          setStatus(1);
          setHash(res.transactionHash);
          setLoading(false);
        })
        .catch((err) => {
          console.log("approve method error:", err);
        });
    } catch (e) {
      console.error("approve error:", e);
    }
  }

  const updateNextParametersInfo = async () => {
    try {
      if (!library) return;
      const web3 = new Web3(library);
      const contract = new web3.eth.Contract(ABI_STAKE, CONTRACT_STAKE);
      if (boostType === BOOST_TYPES[0]) {
        await contract.methods
          .claimPercent2NextPercent(claimPercent)
          .call()
          .then((value) => {
            contract.methods
              .getClaimPercentPrice()
              .call({ from: account })
              .then((res) => {
                setInfoText([
                  "Current level: " + numberToStr(convert(claimPercent, 4), 2),
                  "Next Level: " +
                  (Number(value) === 0 ? "Already maximum level" : numberToStr(convert(value, 4), 2)),
                  "Price: " + numberToStr(convert(res, 18), 4),
                ]);
              })
              .catch((err) => {
                  console.log("get claim percent price method error:", err);
              });
          })
          .catch((err) => {
            console.log("claim percent to next percent method error:", err);
          });
        } else {
          await contract.methods
            .claimHourPeriod2NextHourPeriod(claimHourPeriod)
            .call()
            .then((value) => {
              contract.methods
                .getClaimHourPeriodPrice()
                .call({ from: account })
                .then((res) => {
                  setInfoText([
                    "Current level: " + claimHourPeriod,
                    "Next Level: " + (Number(value) === 0 ? "Already maximum level" : value),
                    "Price: " + numberToStr(convert(res, 18), 4),
                  ]);
                })
                .catch((err) => {
                    console.log("get claim hour period price method error:", err);
                });
            })
            .catch((err) => {
                console.log("claim hour period to next hour period method error:", err);
            });
      }
    } catch (e) {
      console.error("update parameters error:", e);
    }
  }

  const BuyBoost = async (e) => {
    e.preventDefault();
    try {
      if (!library) return;
      const web3 = new Web3(library);
      const contract = new web3.eth.Contract(ABI_STAKE, CONTRACT_STAKE);
      setLoading(true);
      if (boostType === BOOST_TYPES[0]) {
        await contract.methods
          .buyClaimPercent()
          .send({ value: fee, from: account })
          .then((res) => {
            setHash(res.transactionHash);
            setLoading(false);
            toggle();
          })
          .catch((err) => {
            console.log("stake method error:", err);
          });
      } else {
        await contract.methods
          .buyClaimHourPeriod()
          .send({ value: fee, from: account })
          .then((res) => {
            setHash(res.transactionHash);
            setLoading(false);
            toggle();
          })
          .catch((err) => {
            console.log("stake method error:", err);
          });
      }
    } catch (e) {
      console.error("buy boosts error:", e);
    }
  }

  useEffect(() => {
    allow();
    updateNextParametersInfo();
  }, [amount, claimPercent, claimHourPeriod, boostType, library, modal, account, active]);

  useEffect(() => {
    if (Number(balance) === 0) {
      setStatus(0);
    } else if (Number(allowance) < Number(balance)) {
      setStatus(1);
    } else {
      setStatus(2);
    }
  }, [allowance, balance]);

  return (
    <>
      <button className="btn btn-primary" title="In this section, you can improve your staking storage. There are two types of upgrades - increasing the claiming period time (3/6/9/12/24 hours) and purchasing an additional speed multiplier (3/6/9/12/12/15%) which multiplies the base speed of your staking program." onClick={toggle}>
        Boosts
      </button>
      <Modal isOpen={modal} toggle={toggle} className={"modal-app"}>
        <ModalHeader toggle={toggle}>Boosts</ModalHeader>
        <ModalBody>
          <Form className="form">
            <FormGroup>
              <Label for="boostType">Type</Label>
              <Input
                type="select"
                id="boostType"
                value={boostType}
                onChange={(e) => setBoostType(e.currentTarget.value)}
              >
                {BOOST_TYPES.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Form>
          <Card className="modal-info-text" style={{ display: infoText.length === 0 ? "none" : "block" }}>
            <CardBody>
              <CardText>
                {infoText.map((item, index) => (
                  <div key={index}>{item}</div>
                ))}
              </CardText>
            </CardBody>
          </Card>
        </ModalBody>
        <ModalFooter>
          {status === 2 ? (
            <Button color="primary" type={"submit"} onClick={(e) => BuyBoost(e)}>
              Send
            </Button>
          ) : status === 1 ? (
            <Button color="primary" type={"submit"} onClick={(e) => Approve(e)}>
              Approve
            </Button>
          ) : null}
          <Button color="primary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
        <div className={"loading-holder " + (loading ? "active" : "")}>
          <div className={"lds-ellipsis"}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default BoostsModal;
