import { InjectedConnector} from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

const WalletConnect = new WalletConnectConnector({
  rpc: {
    56: 'https://bsc-dataseed1.binance.org:443',
  },
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
  pollingInterval: 12000
});

const Injected = new InjectedConnector({ supportedChainIds: [56, 97, 1337] })

export const connectors = {
  inject: Injected,
  walletConnect: WalletConnect
};
