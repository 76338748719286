import React, { useState } from 'react';
import { Button, Modal, ModalFooter, ModalHeader } from 'reactstrap';
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import { ABI_STAKE, CONTRACT_STAKE } from "../../web3/utils";

const ClaimModal = ({setHash, fee}) => {
  const { account, library } = useWeb3React()
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };
  
  const Claim = async (e) => {
    e.preventDefault();
    try {
      if (!library) return;
      const web3 = new Web3(library);
      const contract = new web3.eth.Contract(ABI_STAKE, CONTRACT_STAKE);
      setLoading(true);
      await contract.methods.claim().send({value: fee, from: account}).then(res=> {
        setHash(res.transactionHash);
        setLoading(false);
        toggle();
      }).catch(err=> {
        console.error("reinvest method error:", err);
      })
    } catch (e) {
      console.error("reinvest error:", e);
    }
  }

  return (
    <>
      <button className="btn btn-primary btn-dynamic" title="Click this button to transfer earned tokens to the Reward section and to start a new staking cycle. When claiming, you must have enough tokens in your wallet to pay the network's fees." onClick={toggle}>
        Claim
      </button>
      <Modal isOpen={modal} toggle={toggle} className={'modal-app'}>
        <ModalHeader toggle={toggle}>
          Claim
        </ModalHeader>
        <ModalFooter>
          <Button color="primary" type={'submit'} onClick={e=>Claim(e)}>
            Send
          </Button>
          <Button color="primary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
        <div className={"loading-holder "+ (loading ? 'active' : '')}>
          <div className={"lds-ellipsis"}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ClaimModal;