import React from 'react';

const ProgressBar = ({ percent }) => {
  const progressStyle = {
    width: `${percent}%`,
    backgroundColor: percent < 90 ? 'rgb(0, 255, 0)' : (percent === 100 ? 'red' : 'rgb(255, 165, 0)'),
  };
  return (
    <div title="Visualization of storage capacity">
      <div className="progress-bar">
        <div className="progress" style={progressStyle}></div>
      </div>
    </div>
  );
};

export default ProgressBar;
