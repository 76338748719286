import React from "react";
import { TmIco } from "../svg/tm";
import { TwIco } from "../svg/tw";
import { LogoFooter } from "../svg/logoFooter";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="left">
          <a href="/" className="logo">
            <LogoFooter/>
          </a>
          <a href="white_paper.pdf" target="_blank" rel="noreferrer" className="police">White Paper</a>
        </div>
        <a href="referral_program.pdf" target="_blank" rel="noreferrer" className="police">Referral Program</a>
        <ul className="social">
          <li>
            <a href="https://t.me/aists_ai" target="_blank" rel="noreferrer">
              <TmIco/>
            </a>
          </li>
          <li>
            <a href="https://twitter.com/AistUniver6365" target="_blank" rel="noreferrer">
              <TwIco/>
            </a>
          </li>
        </ul>
        <div className="copyright">
          <p>AIST 2024 &copy; All rights reserved</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer;