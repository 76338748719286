import React, { useState } from 'react';
import { Button, Modal, ModalFooter, ModalHeader } from 'reactstrap';
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import { ABI_STAKE, CONTRACT_STAKE } from "../../web3/utils";

const ReInvestModal = ({setHash, fee}) => {
  const { account, library } = useWeb3React()
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };

  const Reinvest = async (e) => {
    e.preventDefault();
    try {
      if (!library) return;
      const web3 = new Web3(library);
      const contract = new web3.eth.Contract(ABI_STAKE, CONTRACT_STAKE);
      setLoading(true)
      await contract.methods.reInvest().send({value: fee,from: account}).then(res=> {
        setHash(res.transactionHash);
        setLoading(false)
        toggle();
      }).catch(err=> {
        console.error("reinvest method error:", err);
      })
    } catch (e) {
      console.error("reinvest error:", e);
    }
  }

  return (
    <>
      <button className="btn btn-primary" title="Send claimed tokens to staking to increase your income" onClick={toggle}>
        ReInvest
      </button>
      <Modal isOpen={modal} toggle={toggle} className={'modal-app'}>
        <ModalHeader toggle={toggle}>
          ReInvest
        </ModalHeader>
        <ModalFooter>
          <Button color="primary" type={'submit'} onClick={e=>Reinvest(e)}>
            Send
          </Button>
          <Button color="primary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
        <div className={"loading-holder "+ (loading ? 'active' : '')}>
          <div className={"lds-ellipsis"}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ReInvestModal;