import React from "react";

const LogoutIco = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M7.35719 2.62457e-07L10 5.00876e-07C10.5523 5.00876e-07 11 0.447716 11 1C11 1.55229 10.5523 2 10 2H7.4C6.26339 2 5.47108 2.00078 4.85424 2.05118C4.24907 2.10062 3.90138 2.19279 3.63803 2.32698C3.07354 2.6146 2.6146 3.07354 2.32698 3.63803C2.19279 3.90138 2.10062 4.24907 2.05118 4.85424C2.00078 5.47108 2 6.26339 2 7.4V14.6C2 15.7366 2.00078 16.5289 2.05118 17.1458C2.10062 17.7509 2.19279 18.0986 2.32698 18.362C2.6146 18.9265 3.07354 19.3854 3.63803 19.673C3.90138 19.8072 4.24907 19.8994 4.85424 19.9488C5.47108 19.9992 6.26339 20 7.4 20H10C10.5523 20 11 20.4477 11 21C11 21.5523 10.5523 22 10 22H7.35717C6.27339 22 5.39925 22 4.69138 21.9422C3.96253 21.8826 3.32234 21.7568 2.73005 21.455C1.78924 20.9757 1.02433 20.2108 0.544968 19.27C0.24318 18.6777 0.117368 18.0375 0.0578183 17.3086C-1.77398e-05 16.6007 -9.75112e-06 15.7266 2.62457e-07 14.6428V7.35719C-9.75112e-06 6.27341 -1.77398e-05 5.39926 0.0578183 4.69138C0.117368 3.96253 0.24318 3.32234 0.544968 2.73005C1.02433 1.78924 1.78924 1.02433 2.73005 0.544968C3.32234 0.24318 3.96253 0.117368 4.69138 0.0578183C5.39926 -1.77398e-05 6.27341 -9.75112e-06 7.35719 2.62457e-07ZM16.2929 6.29289C16.6834 5.90237 17.3166 5.90237 17.7071 6.29289L21.7071 10.2929C21.8946 10.4804 22 10.7348 22 11C22 11.2652 21.8946 11.5196 21.7071 11.7071L17.7071 15.7071C17.3166 16.0976 16.6834 16.0976 16.2929 15.7071C15.9024 15.3166 15.9024 14.6834 16.2929 14.2929L18.5858 12H9C8.44772 12 8 11.5523 8 11C8 10.4477 8.44772 10 9 10H18.5858L16.2929 7.70711C15.9024 7.31658 15.9024 6.68342 16.2929 6.29289Z" fill="#9096AA"/>
    </svg>
  )
}

export {LogoutIco};