import React from "react";

const Logo = () => {
  return (
    <svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="42" cy="42" r="42" fill="#171717"/>
      <path d="M51.3163 31.9793L47.1899 53.0529L52.4952 57.7687L71.5057 24.6108L51.3163 31.9793Z" fill="#1BFF49"/>
      <path d="M71.5051 23.4316H15.063L31.7156 38.0211L71.6525 23.4316H71.5051Z" fill="#F6F6F6"/>
      <path d="M29.5055 39.2001L22.7266 41.7054L41.1476 73.8317L45.1266 52.9054L29.5055 39.0527V39.2001Z"
            fill="#F6F6F6"/>
      <path d="M29.5053 38.1685L13 23.4316L22.8737 40.5264L29.6526 38.0211L29.5053 38.1685Z" fill="#F6F6F6"/>
      <path d="M42.1792 74.8633L51.4634 58.6528L46.1581 53.937L42.1792 74.8633Z" fill="#F6F6F6"/>
    </svg>
  )
}

export {Logo};