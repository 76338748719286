import React from "react";
import { Logo } from "../svg/logo";
import { LogoutIco } from "../svg/logout";
import ConnectWallet from "../modal/ConnectWallet";
import { useWeb3React } from "@web3-react/core";
import { CopyToClipboard } from "react-copy-to-clipboard/src";

function Header() {
  const { active, account, deactivate } = useWeb3React();

  return (
    <header>
      <div className="container">
        <a href="/" className="logo">
          <Logo/>
        </a>
        <div className="right">
          <div className="informer">
            {active ? (
              <>
                <div className={"user-info"}>
                  <div className="user" title="This is your wallet address. Click on it to copy it. Your referrals should enter it in the appropriate box when activating steaking so that you both get additional referral rewards.">
                    <CopyToClipboard text={account}>
                      <a className="number-profile">{account}</a>
                    </CopyToClipboard>
                  </div>
                  <div className="btn-user">
                    <button className="btn btn-ico btn-logout" onClick={() => deactivate()}>
                      <LogoutIco/>
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <div>
                <ConnectWallet/>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
